import "./App.css";

function App() {
  const domain = window.location.host;
  return (
    <div className="App">
      <header className="App-header">Welcome on {domain}</header>
    </div>
  );
}

export default App;
